.Button__button___104Gs {
  display: block;
  padding: 1rem 1rem;
  -webkit-appearance: none;
  appearance: none;
  background-color: hsl(15, 70%, 68%);
  color: white;
  border: 1px solid transparent;
  font-size: 1rem;
  cursor: pointer;
  margin: 0;
}

.Button__button___104Gs:focus {
  outline: none;
}

.Button__button___104Gs:not(:disabled):hover,
.Button__button___104Gs:not(:disabled):active {
  background-color: hsl(15, 100%, 68%);
}

.Button__button___104Gs:disabled {
  opacity: 0.5;
  cursor: auto;
}

.Button__expanded___35dBP {
  width: 100%;
}

.Button__secondary___HE42X {
  background-color: hsl(201, 70%, 73%);
}

.Button__secondary___HE42X:not(:disabled):hover,
.Button__secondary___HE42X:not(:disabled):active {
  background-color: hsl(201, 100%, 73%);
}

.Button__tertiary___1f9rm {
  color: inherit;
  background-color: transparent;
  border: 1px solid hsl(15, 100%, 68%);
}

.Button__alert___1LLwR {
  color: white;
  background-color: hsl(0, 65%, 51%);
}

.Button__alert___1LLwR:not(:disabled):hover,
.Button__alert___1LLwR:not(:disabled):active {
  background-color: hsl(0, 85%, 51%);
}

.Button__tertiary___1f9rm:not(:disabled):hover,
.Button__tertiary___1f9rm:not(:disabled):active {
  background-color: rgba(0, 0, 0, 0.05);
}

.Button__tiny___N-C3i {
  padding: 0.5rem 0.5rem;
  font-size: 80%;
  line-height: 1.2;
}

.Button__small___3ibZo {
  padding: 0.75rem 1rem;
  font-size: 80%;
  line-height: 1.2;
}

.Button__large___2GMzU {
  padding: 1.5rem 1rem;
  font-size: 120%;
}

.Button__round___1RqmM {
  border-radius: 30px;
}

.Button__pulse___1Yn82 {
  animation: Button__pulse___1Yn82 2s infinite;
}

.Button__outlined___12UTw {
  border-color: currentColor;
}

@keyframes Button__pulse___1Yn82 {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 1rem rgba(0, 0, 0, 0);
  }
}
