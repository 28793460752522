.Layout__container___1kbRj {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Layout__header___1ihfZ {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid lightgray;
  background-color: white;
  z-index: 2;
}

.Layout__contentContainer___24jyR {
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.Layout__content___f8Z0_ {
  flex: 2;
  height: 100%;
  overflow: hidden;
  background-color: #f0f4fb;
}

.Layout__sidebar___tJJwu {
  flex: 3;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow: hidden;
  z-index: 1;
}

@media (orientation: landscape) {
  .Layout__header___1ihfZ {
    padding: 0.5rem 2rem;
  }
  .Layout__content___f8Z0_ {
    flex: 2;
  }

  .Layout__contentContainer___24jyR {
    flex-direction: row;
  }

  .Layout__sidebar___tJJwu {
    flex: 1;
    border-left: 1px solid lightgray;
    width: 420px;
    min-width: 260px;
    overflow: visible;
  }
}
